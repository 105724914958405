import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import AdminNavbar from '../../../components/AdminNavbar'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import BusinessList from '../../../components/Lists/BusinessList'
import Select from '../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { Container, FilterContainer, Row } from './styles'

function Businesses () {
  const [loading, setLoading] = useState (true)
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [query, setQuery] = useState ('')
  const [orderBy, setOrderBy] = useState (null)
  const [status, setStatus] = useState ({
    label: 'Ativos', value: 'active'
  })
  const [businesses, setBusinesses] = useState ([])
  const token = AuthService.getToken ()

  const selectStyles = {
    boxShadow: '0px 1px 10px -7px',
    borderRadius: 15,
    width: 450,
    height: 38
  }

  const statusOptions = [
    {label: 'Ativos', value: 'active'},
    {label: 'Inativos', value: 'inactive'},
    {label: 'Excluídos', value: 'deleted'}
  ]

  const orderOptions = [
    {label: 'Mais recentes', value: 'newest'},
    {label: 'Mais antigos', value: 'oldest'},
    {label: 'Melhor avaliados', value: 'best_rated'}
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getBusinesses (status, query, orderBy, 1)
  }, [status, orderBy, query])

  const getBusinesses = async (
    selectedStatus = status, nameQuery = query, order = orderBy, pageNumber = page
  ) => {
    if (lastPage && pageNumber > lastPage) return

    try {
      if (pageNumber === 1) setLoading (true)
      const filters = `page=${pageNumber}&name=${nameQuery}&status=${selectedStatus.value}&order=${order?.value}`

      const { data: businessesResponse } = await cutileiApi.get (`/businesses?${filters}`, requestConfig)
      const { data, meta } = businessesResponse
      const businessesData = pageNumber > 1 ? [...businesses, ...data] : data

      setBusinesses (businessesData)
      setPage (pageNumber + 1)
      setLastPage (meta.last_page)
      setTotal (meta.total)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      <FilterContainer>
        <Select
          name='category'
          placeholder='Categoria...'
          value={status}
          options={statusOptions}
          onChange={(_, option) => setStatus (option)}
          containerStyles={selectStyles}
        />
        <Select
          name='orderBy'
          placeholder='Ordenar por...'
          value={orderBy}
          options={orderOptions}
          isClearable={true}
          onChange={(_, option) => setOrderBy (option)}
          containerStyles={selectStyles}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={query}
          onChange={e => setQuery (e.target.value)}
          onClear={() => setQuery ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <BusinessList
          businesses={businesses}
          totalBusinesses={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getBusinesses ()}
        />
      }
    </Container>
  )
}

export default withRouter (Businesses)
