import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function PromoCode ({
  data: promoCode,
  onEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  const handleEdit = () => onEdit (promoCode)

  const deletePromoCode = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/promo_codes/${promoCode.id}`, requestConfig)
      setDeleting (false)
      handleDelete (promoCode)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <SubTitle>
        {promoCode.code}
      </SubTitle>
      <InfoText>
        {promoCode.available_uses}
      </InfoText>
      <InfoText>
        {DateTime.fromISO (promoCode.expires_at).toFormat ('dd/MM/yyyy')}
      </InfoText>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este cupom promocional?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deletePromoCode}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default PromoCode
