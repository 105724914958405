import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../../../services/auth'
import cutileiApi from '../../../../../services/cutileiApi'
import AdminNavbar from '../../../../../components/AdminNavbar'
import AlertDialog from '../../../../../components/AlertDialog'
import { Formik } from 'formik'
import categoryValidator from '../../../../../validators/Admin/categoryValidator'
import { ReactComponent as ButtonLoading } from '../../../../../icons/loading.svg'
import '../../../../../styles/calendar.css'

import {
  Container,
  Form,
  FormField,
  Title,
  Input,
  Button,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function NewProductCategory ({ history }) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <AdminNavbar/>
      <Formik
        validationSchema={categoryValidator}
        initialValues={{
          'name': '',
          'description': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await cutileiApi.post ('/product_categories', values, requestConfig)

            setSubmitting (false)
            toggleOkDialog ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Cadastrar categoria de produto</Title>
            <Row>
              <FormField>
                <Input 
                  placeholder='Nome'
                  value={values.name}
                  onChange={handleChange ('name')} 
                  onBlur={handleBlur ('name')}
                />
                {touched.name && errors.name && (
                  <WarningText>
                    {errors.name}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Input 
                  placeholder='Descrição'
                  value={values.description}
                  onChange={handleChange ('description')} 
                  onBlur={handleBlur ('description')}
                />
                {touched.description && errors.description && (
                  <WarningText>
                    {errors.description}
                  </WarningText>
                )}
              </FormField>
            </Row>

            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}

            <AlertDialog
              visible={showOkDialog}
              title='Sucesso'
              message='Categoria de produto cadastrada!'
              confirmText='Ok'
              onConfirm={() => history.replace ('/admin/config')}
              onClose={() => history.replace ('/admin/config')}
            />

            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Criar categoria'}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withRouter (NewProductCategory)
