import React, { useState, useEffect } from 'react'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import PromoCodeList from '../../../../components/Lists/PromoCodeList'
import AddPromoCodeModal from '../../Modals/AddPromoCodeModal'
import ClearableInput from '../../../../components/Inputs/ClearableInput'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  ListContainer,
  FilterContainer,
  Button
} from '../styles'

function PromoCodes () {
  const [query, setQuery] = useState ('')
  const [loading, setLoading] = useState (true)
  const [promoCodes, setPromoCodes] = useState ([])
  const [showAddPromoCodeModal, setShowAddPromoCodeModal] = useState (false)
  const token = AuthService.getToken ()
  //const [category, setCategory] = useState (localStorage.getItem ('service_category'))

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: promoCodes } = await cutileiApi.get ('/promo_codes', requestConfig)

      setPromoCodes (promoCodes)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const toggleAddPromoCodeModal = () => setShowAddPromoCodeModal (!showAddPromoCodeModal)

  return (
    <ListContainer>
      <FilterContainer>
        <ClearableInput
          placeholder='Pesquisar por código'
          value={query}
          onChange={e => setQuery (e.target.value)}
          onClear={() => setQuery ('')}
          containerStyle={{width: 280}}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
        <Button onClick={toggleAddPromoCodeModal}>
          <FA.FaPlus/>
          Novo cupom
        </Button>
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 30}}/> : (
        <PromoCodeList
          promoCodes={promoCodes}
          onEdit={promoCode => setPromoCodes (promoCodes.map (pc => pc.id === promoCode.id ? {...promoCode} : pc))}
          onDelete={promoCode => setPromoCodes (promoCodes.filter (pc => pc.id !== promoCode.id))}
        />
      )}
      <AddPromoCodeModal
        visible={showAddPromoCodeModal}
        onConfirm={promoCode => setPromoCodes ([promoCode, ...promoCodes])}
        onClose={toggleAddPromoCodeModal}
      />
    </ListContainer>
  )
}

export default PromoCodes
