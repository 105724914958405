import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  background-color: #FFFFFF;
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 10px;
`;

export const RatingContainer = styled.span`
  display: flex;
  position: absolute;
  top: -6px;
  right: 12px;
  height: 18px;
  padding: 0px 6px 0px 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  background-color: #FFFFFF;

  p {
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  margin-right: 20px;
`

export const InfoText = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const SubTitle = styled(InfoText)`
  font-weight: 500;
  margin: 0px;
  align-self: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const LinkButton = styled(Link)`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  color: #000000;

  &:hover {
    background-color: #F7F7F7;
    color: #35A7FF;
  }
`

export const DangerButton = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-decoration: none;
  border: 0px;
  color: #FF3939;
  background-color: transparent;

  &:hover {
    background-color: #FF2020;
    color: #FFFFFF;
  }
`
