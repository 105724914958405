import React from 'react'
import Business from './Business'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { Container, SubTitle } from './styles'

function BusinessList ({
  businesses,
  hasMore,
  totalBusinesses,
  onScroll: handleScroll
}) {
  return (
    <Container>
      <SubTitle>
        {totalBusinesses + ' ' + (totalBusinesses === 1 ? 'Resultado' : 'Resultados')}
      </SubTitle>
      <InfiniteScroll
        dataLength={businesses.length}
        next={handleScroll}
        hasMore={hasMore}
        loader={<Loading/>}
        endMessage={<div style={{height: 50}}></div>}
        style={{overflow: 'visible'}}
      >
        {businesses.map (business => (
          <Business key={business.id} data={business}/>
        ))}
      </InfiniteScroll>
    </Container>
  )
}

export default BusinessList
