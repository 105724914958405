import * as Yup from 'yup'

const promoCodeValidator = Yup.object ({
  code: Yup
    .string ()
    .required ('Insira um código')
    .max (16, 'O código deve ter até 16 caracteres'),

  description: Yup
    .string ()
    .required ('Insira uma descrição')
    .max (256, 'A descrição deve ter até 256 caracteres'),

  expiresAt: Yup
    .string ()
    .required ('Selecione uma data de validade'),
  
  discount_type: Yup
    .object ()
    .nullable ()
    .required ('Selecione um tipo de desconto'),
})

export default promoCodeValidator
