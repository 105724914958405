import React from 'react'
import { FaStar } from 'react-icons/fa'

import {
  Container,
  ImageContainer,
  RatingContainer,
  Row,
  Image,
  SubTitle,
  InfoText,
} from './styles'

function Business ({ data: business }) {
  return (
    <Container to={{
      pathname: `/admin/businesses/${business.id}`,
      state: {business}
    }}>
      <Row>
        <ImageContainer>
          <Image src={business.logo}/>
          {business.ratings_count > 0 && (
            <RatingContainer>
              <FaStar size={12} color='#FFC100'/>
              <SubTitle>
                {business.average_rating}
              </SubTitle>
            </RatingContainer>
          )}
        </ImageContainer>
        <InfoText>
          {business.name}
        </InfoText>
      </Row>
    </Container>
  )
}

export default Business
