import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AdminNavbar from '../../../components/AdminNavbar'
import Services from './Services'
import Products from './Products'
import ServiceCategories from './ServiceCategories'
import ProductCategories from './ProductCategories'
import PromoCodes from './PromoCodes'

import {
  Container,
  Row,
  TabButton
} from './styles'

function Config () {
  const [option, setOption] = useState (localStorage.getItem ('config_option') ?? 'services')
  //const [category, setCategory] = useState (localStorage.getItem ('service_category'))

  const OPTION_LIST = [
    {label: 'Serviços', value: 'services', component: <Services/>},
    {label: 'Categorias de serviço', value: 'service_categories', component: <ServiceCategories/>},
    {label: 'Produtos', value: 'products', component: <Products/>},
    {label: 'Categorias de produto', value: 'product_categories', component: <ProductCategories/>},
    {label: 'Cupons promocionais', value: 'promo_codes', component: <PromoCodes/>},
  ]

  return (
    <Container>
      <AdminNavbar/>
      <Row>
        {OPTION_LIST.map ((s, index) =>
          <TabButton
            key={index}
            selected={option === s.value}
            color={option === s.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => {
              setOption (s.value)
              localStorage.setItem ('config_option', s.value)
            }}
          >
            {s.label}
          </TabButton>
        )}
      </Row>
      {OPTION_LIST.find (o => o.value === option).component}
    </Container>
  )
}

export default withRouter (Config)
