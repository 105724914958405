export default class PromoCode {
  static TYPE_OPTIONS = [
    {label: 'Valor', value: 'value'},
    {label: 'Porcentagem', value: 'percentage'},
    {label: 'Dias gratuitos', value: 'free_days'}
  ]

  static PLAN_OPTIONS = [
    {label: 'Experiência básica', value: 284367},
    {label: 'Experiência completa', value: 279903}
  ]
}
