import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import PromoCode from '../../../../services/PromoCode'
import Select from '../../../../components/Inputs/Select'
import NumberInput from '../../../../components/Inputs/NumberInput'
import MoneyInput from '../../../../components/Inputs/MoneyInput'
import promoCodeValidator from '../../../../validators/promoCodeValidator'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditPromoCodeModal ({
  visible,
  data: promoCode,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={promoCodeValidator}
        initialValues={{
          'code': promoCode.code,
          'description': promoCode.description,
          'availableUses': promoCode.available_uses,
          'expiresAt': promoCode.expires_at,
          'discount_type': PromoCode.TYPE_OPTIONS.find (type => type.value === promoCode.discount.type),
          'plan': PromoCode.PLAN_OPTIONS.find (plan => plan.value === promoCode.discount.plan_id),
          'value': promoCode.discount.value,
          'percentage': promoCode.discount.percentage,
          'free_days': promoCode.discount.free_days,
          'cycles': promoCode.discount.cycles
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const { code, description, availableUses, expiresAt, ...discountData } = values

            const promoCodeData = {
              code,
              description,
              availableUses,
              expiresAt,
              discount: {
                plan_id: discountData.plan.value,
                type: discountData.discount_type.value,
                value: discountData.value,
                percentage: discountData.percentage,
                free_days: discountData.free_days,
                cycles: discountData.cycles
              }
            }

            const { data: editedPromoCode } = await cutileiApi.put (
              `/promo_codes/${promoCode.id}`, promoCodeData, requestConfig
            )

            setSubmitting (false)
            handleConfirm (editedPromoCode)
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {function EditPromoCodeForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur, 
          handleSubmit
        }) {
          return (
            <Form onSubmit={handleSubmit}>
              <Title>Criar cupom promocional</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField stretch={2}>
                  <Label htmlFor='code'>
                    Código do cupom
                  </Label>
                  <Input
                    id='code'
                    placeholder='Código do cupom'
                    value={values.code}
                    onChange={handleChange ('code')} 
                    onBlur={handleBlur ('code')}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='availableUses'>
                    Qtd. disponível
                  </Label>
                  <NumberInput
                    id='availableUses'
                    name='availableUses'
                    placeholder='Qtd. disponível'
                    value={values.availableUses}
                    minValue={1}
                    maxValue={999999}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    style={{width: 'auto'}}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='plan'>
                    Plano
                  </Label>
                  <Select
                    id='plan'
                    name='plan'
                    placeholder='Selecionar...'
                    value={values.plan}
                    options={PromoCode.PLAN_OPTIONS}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.plan}
                    touched={touched.plan}
                  />
                  {touched.plan && errors.plan && (
                    <WarningText>
                      {errors.plan}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='expiresAt'>
                    Válido até
                  </Label>
                  <Input
                    type='date'
                    id='expiresAt'
                    placeholder='Válido até'
                    value={values.expiresAt}
                    onChange={handleChange ('expiresAt')}
                    onBlur={handleBlur ('expiresAt')}
                  />
                  {touched.expiresAt && errors.expiresAt && (
                    <WarningText>
                      {errors.expiresAt}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='description'>
                    Descrição
                  </Label>
                  <Input
                    type='textarea'
                    id='description'
                    placeholder='Descrição'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='discount_type'>
                    Tipo de desconto
                  </Label>
                  <Select
                    id='discount_type'
                    name='discount_type'
                    placeholder='Selecionar...'
                    value={values.discount_type}
                    options={PromoCode.TYPE_OPTIONS}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.discount_type}
                    touched={touched.discount_type}
                    style={{width: '50%'}}
                  />
                  {touched.discount_type && errors.discount_type && (
                    <WarningText>
                      {errors.discount_type}
                    </WarningText>
                  )}
                </FormField>
                {values.discount_type?.value === 'value' && (
                  <FormField>
                    <Label htmlFor='value'>
                      Valor (R$)
                    </Label>
                    <MoneyInput
                      id='value'
                      name='value'
                      placeholder='Valor'
                      value={values.value}
                      onValueChange={setFieldValue}
                      onBlur={handleBlur ('value')}
                    />
                    {touched.value && errors.value && (
                      <WarningText>
                        {errors.value}
                      </WarningText>
                    )}
                  </FormField>
                )}
                {values.discount_type?.value === 'percentage' && (
                  <FormField>
                    <Label htmlFor='percentage'>
                      Porcentagem (%)
                    </Label>
                    <NumberInput
                      id='percentage'
                      name='percentage'
                      placeholder='Porcentagem'
                      value={values.percentage}
                      minValue={1}
                      maxValue={100}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      style={{width: '100%'}}
                    />
                  </FormField>
                )}
                {values.discount_type?.value === 'free_days' && (
                  <FormField>
                    <Label htmlFor='free_days'>
                      Dias gratuitos
                    </Label>
                    <NumberInput
                      id='free_days'
                      name='free_days'
                      placeholder='Dias gratuitos'
                      value={values.free_days}
                      minValue={1}
                      maxValue={99}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      style={{width: '100%'}}
                    />
                  </FormField>
                )}
                {['value', 'percentage'].includes (values.discount_type?.value) && (
                  <FormField>
                    <Label htmlFor='cycles'>
                      Mêses
                    </Label>
                    <NumberInput
                      id='cycles'
                      name='cycles'
                      placeholder='Mêses'
                      value={values.cycles}
                      minValue={1}
                      maxValue={99}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      style={{width: '100%'}}
                    />
                  </FormField>
                )}
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Criar cupom'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditPromoCodeModal
