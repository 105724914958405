import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import TimeWindow from '../../../services/timeWindow'
import Select from '../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Label,
  Input,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function NotifyAbsenceModal ({
  visible,
  title,
  professional,
  isSubmitting,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [startTimeWindows, setStartTimeWindows] = useState ([])
  const [endTimeWindows, setEndTimeWindows] = useState ([])
  const [longestDay, setLongestDay] = useState (null)
  const [loading, setLoading] = useState (true)
  const businessId = AuthService.getBusinessId ()
  const firstName = professional.nickname || professional.name.split (' ')[0]

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: working_days } = await cutileiApi.get (
        `/businesses/${businessId}/working_days`
      )
  
      const longestDay = working_days.sort (day => (
        DateTime.fromISO (day.opening) < DateTime.fromISO (day.closing)
      ))[0]
  
      setStartTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))
      setEndTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))
      setLongestDay (longestDay)
    } catch (error) {
      setErrorMessage (error.response?.data?.message)
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const handleTimeSelected = (name, option, setFieldValue) => {
    if (name === 'startTime') {
      setEndTimeWindows (TimeWindow.getTimeWindows (option.value, longestDay.closing, true))
    } else if (name === 'endTime') {
      setStartTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, option.value, true))
    }
    setFieldValue (name, option)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? (
        <Loading/>
      ) : (
        <Formik
          initialValues={{
            'startDate': DateTime.now ().toISODate (),
            'endDate': DateTime.now ().toISODate (),
            'startTime': startTimeWindows[0],
            'endTime': endTimeWindows[endTimeWindows.length-1]
          }}
          onSubmit={values => {
            const { startDate, endDate, startTime, endTime } = values

            handleConfirm ({
              startDate,
              endDate,
              startTime: startTime.value,
              endTime: endTime.value
            })
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>{title}</Title>
              <InfoText>
                Ao notifcar a asuência desse profissional, tentaremos realocar novos profissionais para seus agendamentos neste dia e avisaremos os clientes.
              </InfoText>
              <InfoText>
                Caso não haja profissional disponível para substituir {firstName} na data e horário do agendamento, avisaremos ao cliente para que o altere.
              </InfoText>
              <InfoText>
                Selecione abaixo as datas e horários da ausência
              </InfoText>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                  <Label htmlFor='startDate'>
                    Data inicial
                  </Label>
                  <Input
                    type='date'
                    id='startDate'
                    placeholder='Data de início'
                    value={values.startDate}
                    onChange={handleChange ('startDate')} 
                    onBlur={handleBlur ('startDate')}
                  />
                  {touched.startDate && errors.startDate && (
                    <WarningText>
                      {errors.startDate}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='endDate'>
                    Data final
                  </Label>
                  <Input
                    type='date'
                    id='endDate'
                    placeholder='Data final'
                    value={values.endDate}
                    onChange={handleChange ('endDate')}
                    onBlur={handleBlur ('endDate')}
                  />
                  {touched.endDate && errors.endDate && (
                    <WarningText>
                      {errors.endDate}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Label htmlFor='startTime'>
                    Horário inicial
                  </Label>
                  <Select
                    id='startTime'
                    name='startTime'
                    placeholder='Horário inicial...'
                    value={values.startTime}
                    options={startTimeWindows}
                    onChange={(name, option) => handleTimeSelected (name, option, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.startTime}
                    touched={touched.startTime}
                  />
                  {touched.startTime && errors.startTime && (
                    <WarningText>
                      {errors.startTime}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='endTime'>
                    Horário final
                  </Label>
                  <Select
                    id='endTime'
                    name='endTime'
                    placeholder='Horário final...'
                    value={values.endTime}
                    options={endTimeWindows}
                    onChange={(name, value) => handleTimeSelected (name, value, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.endTime}
                    touched={touched.endTime}
                  />
                  {touched.endTime && errors.endTime && (
                    <WarningText>
                      {errors.endTime}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <InfoText>{errorMessage}</InfoText>
                </ErrorContainer>
              )}
              <Button
                type='submit'
                disabled={isSubmitting}
                style={{backgroundColor: '#606060', marginBottom: 10}}>
                {isSubmitting ? <ButtonLoading/> : 'Confirmar'}
              </Button>
              <Button type='button'>
                Cancelar
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default NotifyAbsenceModal
