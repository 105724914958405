import React from 'react'

import {
  Container,
  SubTitle,
  Row
} from '../styles'

function CustomerProducts ({ customer }) {
  return (
    <Container style={{marginBottom: 20}}>
      <Row>
        <SubTitle>Produtos</SubTitle>
      </Row>
    </Container>
  )
}

export default CustomerProducts
