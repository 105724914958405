import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import ServiceForm from '../../../../components/Forms/ServiceForm'
import ClearableInput from '../../../../components/Inputs/ClearableInput'
import Select from '../../../../components/Inputs/Select'
import { Formik } from 'formik'
import businessServiceValidator from '../../../../validators/businessServiceValidator'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Content,
  Row,
  Title,
  InfoText,
  Button,
  CloseButton
} from '../styles'

function BusinessServicesModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [loading, setLoading] = useState (true)
  const [services, setServices] = useState ([])
  const [categories, setCategories] = useState ([])
  const [category, setCategory] = useState (null)
  const [name, setName] = useState ('')
  const [orderBy, setOrderBy] = useState ('')
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [category, name])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: categories } = await cutileiApi.get ('/service_categories', requestConfig)

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))

      await getServices ()
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const getServices = async () => {
    let filters = `business_id=${businessId}`
    if (name) filters += `&name=${name}`
    if (category) filters += `&category_id=${category.value}`
    if (orderBy) filters += `&order_by=${orderBy}`

    try {
      setLoading (true)

      const { data: services } = await cutileiApi.get (
        `/services?${filters}`, requestConfig
      )

      setServices (services)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Header>
        <Title>
          Adicionar serviços
        </Title>
        <CloseButton onClick={handleClose}>
          <FA.FaTimes color='#FF3939' size={18}/>
        </CloseButton>
      </Header>
      <Row style={{marginBottom: 15, marginInline: 20}}>
        <Select
          name='category'
          placeholder='Categoria...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={name}
          onChange={e => setName (e.target.value)}
          onClear={() => setName ('')}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
      </Row>
      {loading ? (
        <Loading/>
      ) : (
        <Formik
          validationSchema={businessServiceValidator}
          initialValues={{
            'services': services.map (service => ({
              'id': service.id,
              'selected': false,
              'name': service.name,
              'nickname': '',
              'duration': 15,
              'max_delay': 15,
              'price': 0,
              'comission_percentage': 40
            }))
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const services = values.services.filter (service => service.selected).map (service => (
              {...service, comission_percentage: service.comission_percentage / 100}
            ))

            try {
              await cutileiApi.post (`/businesses/${businessId}/services`, {services}, requestConfig)

              setSubmitting (false)
              handleConfirm (services)
              handleClose ()
            } catch (error) {
              setSubmitting (false)
              console.log (error)
            }
          }}
        >
          {(formikProps) => (
            <Container>
              <Content>
                {services.length === 0 ? (
                  <InfoText>
                    Nenhum resultado encontrado
                  </InfoText>
                ) : services.map ((service, index) => 
                  <ServiceForm 
                    key={service.id}
                    index={index}
                    formikProps={formikProps}
                    data={service}
                  />
                )}
              </Content>
              <Row>
                <Button type='submit' onClick={formikProps.handleSubmit}>
                  {formikProps.isSubmitting ? <ButtonLoading/> : 'Confirmar'}
                </Button>
              </Row>
            </Container>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default BusinessServicesModal
