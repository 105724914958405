import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  Row,
  SubTitle,
  InfoText,
  MoneyTextMask,
  Button
} from './styles'

function BusinessProduct ({
  data: product,
  onEdit,
  onDelete: handleDelete
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (product)

  const deleteProduct = async () => {
    setDeleting (true)
    try {
      await cutileiApi.delete (`/businesses/${businessId}/products/${product.id}`, requestConfig)
      setDeleting (false)
      handleDelete (product)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)

  return (
    <Container>
      <Row>
        <SubTitle>
          {product.nickname || product.name}
        </SubTitle>
      </Row>
      <InfoText>
        {product.stock}
      </InfoText>
      <MoneyTextMask value={product.price} style={{justifySelf: 'center'}}/>
      <InfoText>
        {product.comission_percentage * 100}%
      </InfoText>
      <Row style={{justifySelf: 'center'}}>
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        <Button onClick={toggleAlertDialog}>
          {deleting ? (
            <ButtonLoading style={{margin: 0}}/>
          ) : (
            <FA.FaTrash color='#FF3939' size={16}/>
          )}
        </Button>
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este produto?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteProduct}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BusinessProduct
