import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import ScheduleService from '../../../services/Schedule'
import NumberInput from '../../../components/Inputs/NumberInput'
import MoneyInput from '../../../components/Inputs/MoneyInput'
import editServiceValidator from '../../../validators/editServiceValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Input,
  Button,
  CloseButton,
  Row,
  Title,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function EditServiceModal ({
  visible,
  professionalID,
  data: service,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  
  const calculateComissionValue = (price, comissionPercentage) => {
    return price * (comissionPercentage / 100 || 0)
  }

  const calculateComissionPercentage = (price, comissionValue) => {
    return Math.round ((comissionValue / price) * 100)
  }

  const recalcutaleComissionValue = (values, setFieldValue) => {
    const comissionValue = calculateComissionValue (values.price, values.comission_percentage)
    setFieldValue ('professional_comission', comissionValue)
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={editServiceValidator}
        initialValues={{
          'id': service.id,
          'nickname': service.nickname || service.name,
          'duration': service.duration,
          'max_delay': service.max_delay,
          'price': service.price,
          'comission_percentage': service.comission_percentage * 100,
          'professional_comission': service.comission_percentage * service.price ?? 0,
          'business_comission': service.price - (service.comission_percentage * service.price ?? 0),
          'cutilei_service': service.cutilei_service
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const data = {...values, comission_percentage: values.comission_percentage / 100}
            const url = professionalID
              ? `/businesses/${businessId}/professionals/${professionalID}/services/${values.id}`
              : `/businesses/${businessId}/services/${values.id}`

            await cutileiApi.put (url, data, requestConfig)

            setSubmitting (false)
            handleConfirm ({...service, ...data})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {function EditServiceForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur, 
          handleSubmit
        }) {
          useEffect (() => {
            recalcutaleComissionValue (values, setFieldValue)
          }, [values.price])

          useEffect (() => {
            setFieldValue ('business_comission', Math.abs (values.price - values.professional_comission))
          }, [values.price, values.comission_percentage, values.professional_comission])

          return (
            <Form onSubmit={handleSubmit}>
              <Title>Editar serviço</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row style={{
                display: 'grid',
                gridTemplateColumns: values.cutilei_service ? '1fr 1fr' : '2fr 1fr 1fr'
              }}>
                {!values.cutilei_service && (
                  <FormField>
                    <Label htmlFor='nickname'>
                      Apelido
                    </Label>
                    <Input
                      id='nickname'
                      placeholder='Apelido'
                      value={values.nickname}
                      onChange={handleChange ('nickname')} 
                      onBlur={handleBlur ('nickname')}
                    />
                  </FormField>
                )}
                <FormField>
                  <Label htmlFor='duration'>
                    Tempo do serviço (mins) {values.duration >= 60 && ` - ${
                      ScheduleService.getFormattedDuration (values.duration)
                    }`}
                  </Label>
                  <NumberInput
                    id='duration'
                    name='duration'
                    value={values.duration}
                    minValue={15}
                    stepOnly={true}
                    step={values.duration < 60 ? 15 : 30}
                    onChange={(name, value) => {
                      const newValue = values.duration === 60 && values.duration > value ? 45 : value
                      setFieldValue (name, newValue)
                    }}
                    style={{width: '100%'}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='max_delay'>
                    Tolerância de atraso (mins)
                  </Label>
                  <NumberInput
                    id='max_delay'
                    name='max_delay'
                    value={values.max_delay}
                    minValue={5}
                    maxValue={values.cutilei_service ? 20 : 60}
                    onChange={setFieldValue}
                    style={{width: '100%'}}
                    data-for='max_delay_tooltip'
                    data-tip
                  />
                  <ReactTooltip
                    id='max_delay_tooltip'
                    effect='solid'
                    place='bottom' 
                    backgroundColor='#252525'
                    tooltipRadius='10'
                  >
                    <Label style={{color: '#FFFFFF', width: 200}}>
                      A tolerância de atraso para serviços pode ser configurada entre 5 e 20 minutos.
                    </Label>
                  </ReactTooltip>
                </FormField>
              </Row>
              <Row style={{alignItems: 'flex-start'}}>
                <FormField>
                  <Label htmlFor='price'>
                    Preço (R$)
                  </Label>
                  <MoneyInput
                    id='price'
                    name='price'
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                    disabled={values.cutilei_service}
                  />
                  {touched.price && errors.price && (
                    <WarningText>
                      {errors.price}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Label htmlFor='comission_percentage'>
                    Comissão (%)
                  </Label>
                  <NumberInput
                    id='comission_percentage'
                    name='comission_percentage'
                    placeholder='Comissão (%)'
                    value={values.comission_percentage}
                    minValue={0}
                    maxValue={100}
                    onChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('professional_comission', calculateComissionValue (values.price, value))
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='professional_comission'>
                    Valor (profissional)
                  </Label>
                  <MoneyInput
                    id='professional_comission'
                    name='professional_comission'
                    placeholder='Valor líquido'
                    value={values.professional_comission}
                    maxValue={values.price}
                    onValueChange={(name, value) => {
                      setFieldValue (name, value)
                      setFieldValue ('comission_percentage', calculateComissionPercentage (values.price, value))
                    }}
                    onBlur={handleBlur ('professional_comission')}
                    style={{color: '#44CF6C', fontWeight: 600}}
                  />
                </FormField>
                <FormField>
                  <Label htmlFor='business_comission'>
                    Valor (salão)
                  </Label>
                  <MoneyInput
                    id='business_comission'
                    name='business_comission'
                    value={values.business_comission}
                    style={{color: '#44CF6C', fontWeight: 600}}
                    disabled
                  />
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditServiceModal
