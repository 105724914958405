import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import BusinessBillList from '../../../components/Lists/BusinessBillList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  CloseButton,
  Title
} from './styles'

function BusinessBillsModal ({
  visible,
  date,
  paymentMethod,
  bankAccount,
  cardFlag,
  onClose: handleClose
}) {
  const [loading, setLoading] = useState (true)
  const [bills, setBills] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    getBills ()
  }, [visible])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getBills = async () => {
    let filters = `business_id=${businessId}&deleted=false&date=${date}`
    if (paymentMethod) filters += `&payment_method_id=${paymentMethod?.id}`
    if (bankAccount) filters += `&bank_account_id=${bankAccount?.id}`
    if (cardFlag) filters += `&card_flag_id=${cardFlag?.id}`

    try {
      const { data: bills } = await cutileiApi.get (
        `/business_bills?${filters}`, requestConfig
      )
      setBills (bills)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Header>
          <Title>Comandas</Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        {loading ? <Loading style={{marginBottom: 20}}/> : (
          <BusinessBillList
            bills={bills}
            paymentMethod={paymentMethod}
            cardFlag={cardFlag}
            bankAccount={bankAccount}
            containerStyle={{paddingInline: 10}}
            onEdit={bill => setBills (bills.map (b => b.id === bill.id ? {...bill} : b))}
            onDelete={bill => setBills (bills.filter (b => b.id !== bill.id))}
          />
        )}
      </Container>
    </Modal>
  )
}

export default BusinessBillsModal
