import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../../services/cutileiApi'
import AuthService from '../../../../services/auth'
import CategoryList from '../../../../components/Lists/CategoryList'
import ClearableInput from '../../../../components/Inputs/ClearableInput'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'

import {
  ListContainer,
  FilterContainer,
  Button
} from '../styles'

function ServiceCategories ({ history }) {
  const [query, setQuery] = useState ('')
  const [loading, setLoading] = useState (true)
  const [categories, setCategories] = useState ([])
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [query])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: categories } = await cutileiApi.get (
        `/service_categories?name=${query}`, requestConfig
      )

      setCategories (categories)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <ListContainer>
      <FilterContainer>
        <ClearableInput
          placeholder='Pesquisar por nome'
          value={query}
          onChange={e => setQuery (e.target.value)}
          onClear={() => setQuery ('')}
          containerStyle={{width: 280}}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
        <Button onClick={() => history.push ('/admin/service_categories/new')}>
          <FA.FaPlus/>
          Nova categoria
        </Button>
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <CategoryList categories={categories} categoryType='service'/>
      }
    </ListContainer>
  )
}

export default withRouter (ServiceCategories)
