import * as FA from 'react-icons/fa'

const NavbarData = [
  {
    label: 'Financeiro',
    id: 'financial',
    path: '/admin',
    icon: <FA.FaDollarSign/>
  },
  {
    label: 'Membros',
    id: 'members',
    path: '/admin/members',
    icon: <FA.FaUser/>
  },
  {
    label: 'Salões',
    id: 'businesses',
    path: '/admin/businesses',
    icon: <FA.FaStoreAlt/>
  },
  {
    label: 'Consumo',
    id: 'usage',
    path: '/admin/usage',
    icon: <FA.FaChartBar/>
  },
  {
    label: 'Configurações',
    id: 'config',
    path: '/admin/config',
    icon: <FA.FaCog/>
  },
]

export default NavbarData
