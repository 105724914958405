import React, { useState } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import NavbarData from './data' 
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import ReactTooltip from 'react-tooltip'
import * as FA from 'react-icons/fa'
import { IconContext } from 'react-icons'

import {
  Container,
  Image,
  NavItem,
  Label
} from './styles'

function AdminNavbar ({ history }) {
  const [loading, setLoading] = useState (false)
  const signed = AuthService.isSigned ()
  const admin = AuthService.getUser ()
  const token = AuthService.getToken ()

  const handleSignOut = async () => {
    try {
      setLoading (true)
      await cutileiApi.delete ('/auth/admins', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setLoading (false)
      AuthService.adminSignOut ()
      history.replace ('/admin')
    } catch (error) {
      setLoading (false)
      console.log (error.response.data)
      if (error.response.status === 401) {
        AuthService.adminSignOut ()
        history.replace ('/')
      }
    }
  }

  return (
    <IconContext.Provider value={{color: '#252525'}}>
      <Container>
        {signed ? (
          <>
            <Image src={admin.profile_picture} alt={admin.name}/>
            {NavbarData.map ((item, index) => (
              <NavItem key={index}>
                <NavLink
                  to={item.path}
                  activeClassName='active'
                  exact
                  data-for={item.id}
                  data-tip
                >
                  {item.icon}
                </NavLink>
                <ReactTooltip id={item.id} effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                  <Label>{item.label}</Label>
                </ReactTooltip>
              </NavItem>
            ))}
            <NavItem>
              <Link to='/' onClick={handleSignOut} data-for='logout' data-tip>
                <FA.FaSignOutAlt/>
              </Link>
              <ReactTooltip id='logout' effect='solid' place='right' backgroundColor='#252525' tooltipRadius='10'>
                <Label>Sair</Label>
              </ReactTooltip>
            </NavItem>
          </>
          ) : (
            <NavItem>
              <NavLink to='/login' activeClassName='active'>
                <FA.FaSignInAlt/>
              </NavLink>
            </NavItem>
          )
        }
      </Container>
    </IconContext.Provider>
  )
}

export default withRouter (AdminNavbar)
