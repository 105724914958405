import React, { useState } from 'react'
import PromoCode from './PromoCode'
import EditPromoCodeModal from '../../../pages/Admin/Modals/EditPromoCodeModal'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function PromoCodeList ({
  promoCodes,
  onEdit: handleEdit,
  onDelete: handleDelete
}) {
  const [promoCodeData, setPromoCodeData] = useState (null)
  const [showEditPromoCodeModal, setShowEditPromoCodeModal] = useState (false)
  
  const editPromoCode = promoCode => {
    setPromoCodeData (promoCode)
    toggleEditPromoCodeModal ()
  }

  const toggleEditPromoCodeModal = () => setShowEditPromoCodeModal (!showEditPromoCodeModal) 

  return (
    <Container>
      <SubTitle>
        {promoCodes.length + ' ' + (promoCodes.length === 1 ? 'Resultado' : 'Resultados')}
      </SubTitle>
      <LabelRow>
        <SubTitle>Código</SubTitle>
        <SubTitle>Qtd. disponível</SubTitle>
        <SubTitle>Validade</SubTitle>
        <SubTitle>Ações</SubTitle>
      </LabelRow>
      {promoCodes.map (promoCode =>
        <PromoCode
          key={promoCode.id}
          data={promoCode}
          onEdit={editPromoCode}
          onDelete={handleDelete}
        />
      )}
      {promoCodeData && (
        <EditPromoCodeModal
          visible={showEditPromoCodeModal}
          data={promoCodeData}
          onClose={toggleEditPromoCodeModal}
          onConfirm={handleEdit}
        />
      )}
    </Container>
  )
}

export default PromoCodeList
