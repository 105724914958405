import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
`

export const FilterContainer = styled(Container)`
  flex-direction: row;
  width: 680px;
  height: auto;
  gap: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
`
