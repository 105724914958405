import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import TimeWindow from '../../../../services/timeWindow'
import Select from '../../../../components/Inputs/Select'
import CheckBox from '../../../../components/CheckBox'
import AlertDialog from '../../../../components/AlertDialog'
import { Formik } from 'formik'
import editAvaliabilityValidator from '../../../../validators/editAvaliabilityValidator'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading.svg'

import {
  Container,
  Form,
  Button,
  SubTitle,
  InfoText,
  FormField,
  Label,
  WarningText,
  ErrorContainer,
  Row
} from '../../styles'

function EditWorkingDays ({ history }) {
  const [loading, setLoading] = useState (true)
  const [business, setBusiness] = useState (null)
  const [weekdays, setWeekdays] = useState ([])
  const [workingDays, setWorkingDays] = useState ([])
  const [openingTimeWindows, setOpeningTimeWindows] = useState ([])
  const [closingTimeWindows, setClosingTimeWindows] = useState ([])
  const [cutileiOpeningTimeWindows, setCutileiOpeningTimeWindows] = useState ({})
  const [cutileiClosingTimeWindows, setCutileiClosingTimeWindows] = useState ({})
  const [errorMessage, setErrorMessage] = useState (null)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()
  const isTutorial = history.location.state?.isTutorial

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    setLoading (true)
    
    try {
      const { data: business } = await cutileiApi.get (`/businesses/${businessId}`)
      const { data: weekdays } = await cutileiApi.get ('/weekdays')
      const { data: workingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)
      const timeWindows = TimeWindow.getTimeWindows ('6:00', '22:00')
      const cutileiTimeWindows = {...weekdays.map (() => timeWindows)}

      setBusiness (business)
      setWeekdays (weekdays)
      setWorkingDays (workingDays)
      setOpeningTimeWindows (timeWindows)
      setClosingTimeWindows (timeWindows)
      setCutileiOpeningTimeWindows (cutileiTimeWindows)
      setCutileiClosingTimeWindows (cutileiTimeWindows)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const handleTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])

    if (name.includes ('opening')) {
      setClosingTimeWindows (TimeWindow.getTimeWindows (option.value, '22:00', true))
      setCutileiOpeningTimeWindows ({
        [dayIndex]: TimeWindow.getTimeWindows (option.value, workingDays[dayIndex].closing.value || '22:00')
      })
    } else if (name.includes ('closing')) {
      setOpeningTimeWindows (TimeWindow.getTimeWindows ('6:00', option.value, true))
      setCutileiClosingTimeWindows ({
        [dayIndex]: TimeWindow.getTimeWindows (workingDays[dayIndex].opening.value || '6:00', option.value)
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      opening: name.includes ('opening') && day.opening === ''
        ? option : index === dayIndex && name.includes ('opening')
          ? option : day.opening,
      closing: name.includes ('closing') && day.closing === ''
        ? option : index === dayIndex && name.includes ('closing')
          ? option : day.closing
    })))
  }

  const handleCutileiTimeSelected = (name, option, workingDays, setFieldValue) => {
    const dayIndex = parseInt (name.split ('.')[1])
    const lastTimeWindowIndex = cutileiClosingTimeWindows[dayIndex].length -1

    if (name.includes ('cutilei_opening')) {
      setCutileiClosingTimeWindows ({
        ...cutileiClosingTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          option.value, cutileiClosingTimeWindows[dayIndex][lastTimeWindowIndex].value, true
        )
      })
    } else if (name.includes ('cutilei_closing')) {
      setCutileiOpeningTimeWindows ({
        ...cutileiOpeningTimeWindows,
        [dayIndex]: TimeWindow.getTimeWindows (
          cutileiOpeningTimeWindows[dayIndex][0].value, option.value, true
        )
      })
    }

    setFieldValue ('working_days', workingDays.map ((day, index) => ({
      ...day,
      cutilei_opening: name.includes ('cutilei_opening') && day.cutilei_opening === ''
        ? option : index === dayIndex && name.includes ('cutilei_opening')
          ? option : day.cutilei_opening,
      cutilei_closing: name.includes ('cutilei_closing') && day.cutilei_closing === ''
        ? option : index === dayIndex && name.includes ('cutilei_closing')
          ? option : day.cutilei_closing
    })))
  }

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return loading ? <Loading style={{marginTop: 30}}/> : (
    <Container>
      <Formik
        validationSchema={editAvaliabilityValidator}
        initialValues={{
          'working_days': weekdays.map (day => {
            const workingDay = workingDays.find (workingDay => workingDay.id === day.id)

            if (workingDay) {
              const opening = TimeWindow.toShortTime (workingDay.opening)
              const closing = TimeWindow.toShortTime (workingDay.closing)
              const cutilei_opening = TimeWindow.toShortTime (workingDay.cutilei_opening || workingDay.opening)
              const cutilei_closing = TimeWindow.toShortTime (workingDay.cutilei_closing || workingDay.closing)
              
              return { 
                ...workingDay,
                opening: { label: opening, value: opening },
                closing: { label: closing, value: closing },
                cutilei_opening: { label: cutilei_opening, value: cutilei_opening },
                cutilei_closing: { label: cutilei_closing, value: cutilei_closing },
                selected: true
              } 
            } else return {
              ...day, opening: '', closing: '', cutilei_opening: '', cutilei_closing: '', selected: false
            }
          })
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setErrorMessage (null)
          setSubmitting (true)

          try {
            const working_days = values.working_days.filter (day => day.selected).map (day => ({
              ...day,
              opening: day.opening.value,
              closing: day.closing.value, 
              cutilei_opening: day.cutilei_opening.value,
              cutilei_closing: day.cutilei_closing.value
            }))

            await cutileiApi.post (`/businesses/${businessId}/working_days`, {working_days}, requestConfig)

            setSubmitting (false)
            toggleOkDialog ()
          } catch (error) {
            if (error.response?.status === 400) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error.response.data)
          }
        }}
      >
        {function WorkingDaysForm ({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setTouched,
          handleSubmit
        }) {
          const handleDaySelected = (value, index) => {
            setFieldValue (`working_days.${index}`, {
              ...values.working_days[index],
              ...value === true && {
                  opening: '',
                  closing: '',
                  cutilei_opening: '',
                  cutilei_closing: '',
                  accepts_cutilei: false
                },
                selected: value
              })
  
            if (value === true) setTouched ({
              [`working_days.${index}.opening`]: false,
              [`working_days.${index}.closing`]: false,
              [`working_days.${index}.cutilei_opening`]: false,
              [`working_days.${index}.cutilei_closing`]: false,
              [`working_days.${index}.accepts_cutilei`]: false
            })
          }

          const handleAcceptsCutileiSelected = (value, index) => {
            setFieldValue (`working_days.${index}`, {
              ...values.working_days[index],
              ...value === true ? {
                cutilei_opening: values.working_days[index].opening,
                cutilei_closing: values.working_days[index].closing
              } : {
                cutilei_opening: '',
                cutilei_closing: ''
              },
              accepts_cutilei: value
            })
  
            if (value === true) setTouched ({
              [`working_days.${index}.cutilei_opening`]: false,
              [`working_days.${index}.cutilei_closing`]: false
            })
          }

          return (
            <Form onSubmit={handleSubmit}>
              <SubTitle>Disponibilidade de atendimento da agenda do salão</SubTitle>
              <InfoText style={{marginBottom: 15}}>
                As alterações feitas na sua agenda serão atualizadas automaticamente na agenda dos(as) profissionais
              </InfoText>
              <Row style={{alignItems: 'flex-start'}}>
                {values.working_days.map ((day, index) => (
                  <FormField key={day.id}>
                    <Row style={{marginBottom: -5, alignItems: 'center'}}>
                      <CheckBox
                        id={`working_days.${index}.selected`}
                        style={{marginRight: 6}}
                        value={values.working_days[index].selected}
                        onValueChange={value => handleDaySelected (value, index)}
                      />
                      <Label htmlFor={`working_days.${index}.selected`}>
                        {day.name}
                      </Label>
                    </Row>
                    {values.working_days[index].selected && (
                      <>
                        <Select
                          name={`working_days.${index}.opening`}
                          placeholder='Abertura...'
                          value={values.working_days[index].opening}
                          options={openingTimeWindows}
                          onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                          onBlur={setFieldTouched}
                          containerStyles={{marginTop: '10px'}}
                          error={errors.working_days?.[index]}
                          touched={touched.working_days?.[index]}
                        />
                        {touched.working_days?.[index]?.opening && errors.working_days?.[index]?.opening && (
                          <WarningText>{errors.working_days[index].opening}</WarningText>
                        )}
                        <Select
                          name={`working_days.${index}.closing`}
                          placeholder='Fechamento...'
                          value={values.working_days[index].closing}
                          options={closingTimeWindows}
                          onChange={(name, option) => handleTimeSelected (name, option, values.working_days, setFieldValue)}
                          onBlur={setFieldTouched}
                          containerStyles={{marginTop: '10px'}}
                          error={errors.working_days?.[index]}
                          touched={touched.working_days?.[index]}
                        />
                        {touched.working_days?.[index]?.closing && errors.working_days?.[index]?.closing && (
                          <WarningText>{errors.working_days[index].closing}</WarningText>
                        )}
                      </>
                    )}
                  </FormField>
                ))}
              </Row>
              {values.working_days.some (day => day.selected) && (
                <SubTitle>Disponibilidade de atendimento para a Cutilei</SubTitle>
              )}
              <Row style={{alignItems: 'flex-start'}}>
                {values.working_days.map ((day, index) => (
                  day.selected ? (
                    <FormField key={index}>
                      <Row style={{marginBottom: -5, alignItems: 'center'}}>
                        <CheckBox
                          id={`working_days.${index}.accepts_cutilei`}
                          style={{marginRight: 6, marginTop: 3}}
                          value={day.accepts_cutilei}
                          onValueChange={value => handleAcceptsCutileiSelected (value, index)}
                        />
                        <Label htmlFor={`working_days.${index}.accepts_cutilei`}>
                          {day.name}
                        </Label>
                      </Row>
                      {day.accepts_cutilei && (
                        <>
                          <Select
                            name={`working_days.${index}.cutilei_opening`}
                            placeholder='Abertura...'
                            value={day.cutilei_opening}
                            options={cutileiOpeningTimeWindows[index]}
                            onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                            onBlur={setFieldTouched}
                            containerStyles={{marginTop: '10px'}}
                            error={errors.working_days?.[index]}
                            touched={touched.working_days?.[index]}
                          />
                          {touched.working_days?.[index]?.cutilei_opening && errors.working_days?.[index]?.cutilei_opening && (
                            <WarningText>{errors.working_days[index].cutilei_opening}</WarningText>
                          )}
                          <Select
                            name={`working_days.${index}.cutilei_closing`}
                            placeholder='Fechamento...'
                            value={day.cutilei_closing}
                            options={cutileiClosingTimeWindows[index]}
                            onChange={(name, option) => handleCutileiTimeSelected (name, option, values.working_days, setFieldValue)}
                            onBlur={setFieldTouched}
                            containerStyles={{marginTop: '10px'}}
                            error={errors.working_days?.[index]}
                            touched={touched.working_days?.[index]}
                          />
                          {touched.working_days?.[index]?.cutilei_closing && errors.working_days?.[index]?.cutilei_closing && (
                            <WarningText>{errors.working_days[index].cutilei_closing}</WarningText>
                          )}
                        </>
                      )}
                    </FormField>
                  ) : (
                    <FormField key={index}/>
                  )
                ))}
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <AlertDialog
                visible={showOkDialog}
                title='Sucesso'
                message={
                  isTutorial ? <>
                    <InfoText>
                      Agenda alterada!
                      Deseja voltar para o guia de configuração do salão ou continuar editando a agenda?
                    </InfoText>
                  </> : 'Agenda alterada!'
                }
                confirmText={isTutorial ? 'Voltar para o guia' : 'Ok'}
                cancelText={isTutorial ? 'Continuar na agenda' : ''}
                onConfirm={isTutorial ? () => history.replace ('/business') : toggleOkDialog}
                onClose={toggleOkDialog}
                containerStyle={isTutorial && {width: 400}}
                buttonStyle={isTutorial && {width: 160}}
              />
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default EditWorkingDays
