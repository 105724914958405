import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FFFFFF;
  margin: 0px 10px 12px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  justify-content: space-between; 
  align-items: center;
  justify-items: center;
  padding: 7px 20px;

  &:hover {
    background-color: #F7F7F7;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0px;
`

export const Button = styled.button`
  width: 32px;
  height: 32px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    background-color: #F0F0F0;
    box-shadow: 0px 2px 10px -6px #252525;
  }
`
