import React, { useState } from 'react'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import NumberInput from '../../Inputs/NumberInput'
import MoneyInput from '../../Inputs/MoneyInput'
import Select from '../../Inputs/Select'
import billBundleValidator from '../../../validators/billBundleValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  Form,
  FormField,
  Button,
  CloseButton,
  FieldGrid,
  Row,
  InfoText,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function BillBundleForm ({
  bill,
  bundles,
  services,
  professionals,
  billSchedules = [],
  billServices = [],
  onConfirm: handleConfirm,
  onSplitServices: handleSplitServices,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleBundleSelected = async (name, option, setFieldValue) => {
    const bundle = option.value

    setFieldValue ('custom_price', bundle.price)
    setFieldValue ('custom_comission_percentage', bundle.comission_percentage * 100)
    setFieldValue (name, option)
  }

  const addServiceToBill = async (service, professionalId) => {
    const { data: addedService } = await cutileiApi.post (
      `/business_bills/${bill.id}/services`, {
        service_id: service.id,
        professional_id: professionalId ?? null,
        amount: 1,
        custom_price: service.price,
        custom_comission_percentage: service.comission_percentage
      }, requestConfig
    )

    return addedService
  }

  const splitHandAndFeetServices = async bundleServices => {
    const addedServices = []
    const removedServices = []
    const removedSchedules = []
    const handAndFootServices = services.filter (s => (
      s.name.toLowerCase () === 'mão' || s.name.toLowerCase () === 'pé'
    ))

    const billHandAndFootSchedules = billSchedules.filter (s => s.service.name.toLowerCase () === 'pé e mão')
    const billHandAndFootServices = billServices.filter (s => s.name.toLowerCase () === 'pé e mão')
    const bundleHasHandOrFeetService = bundleServices.some (bundleService => (
      handAndFootServices.some (service => service.id === bundleService.id) && bundleService.amount > 0
    ))

    if (bundleHasHandOrFeetService) {
      await Promise.all (billHandAndFootSchedules.map (async schedule => {
        await cutileiApi.put (
          `/schedules/${schedule.id}/activation`, {billActive: false}, requestConfig
        )
        removedSchedules.push (schedule)

        await Promise.all (handAndFootServices.map (async service => {
          const addedService = await addServiceToBill (service, schedule.professional.id)
          addedServices.push (addedService)
        }))
      }))
      await Promise.all (billHandAndFootServices.map (async billService => {
        await cutileiApi.delete (
          `/business_bills/${bill.id}/services/${billService.id}`, requestConfig
        )
        removedServices.push (billService)

        await Promise.all (handAndFootServices.map (async service => {
          const addedService = await addServiceToBill (service, billService.professional_id)
          addedServices.push (addedService)
        }))
      }))

      handleSplitServices (addedServices, removedServices, removedSchedules)
    }
  }

  return (
    <Formik
      validationSchema={billBundleValidator}
      initialValues={{
        'bundle': null,
        'professional': null,
        'amount': 1,
        'custom_price': 0,
        'custom_comission_percentage': 0
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const bundleData = {
          bundle_id: values.bundle.value.id,
          professional_id: values.professional?.value || null,
          amount: values.amount,
          custom_price: values.custom_price,
          custom_comission_percentage: values.custom_comission_percentage / 100
        }

        try {
          const { data: bundle } = await cutileiApi.post (
            `/business_bills/${bill.id}/bundles`, bundleData, requestConfig
          )

          const bundleServices = values.bundle.value.services
          const handAndFootServices = services.filter (s => (
            s.name.toLowerCase () === 'mão' || s.name.toLowerCase () === 'pé'
          ))
  
          const bundleHasHandOrFeetService = bundleServices.some (bundleService => (
            handAndFootServices.some (service => service.id === bundleService.id) && bundleService.amount > 0
          ))
  
          if (bundleHasHandOrFeetService) await splitHandAndFeetServices (bundleServices)

          setSubmitting (false)
          handleConfirm (bundle)
          handleClose ()
        } catch (error) {
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
          console.log (error)
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleSubmit
      }) => (
        <Form>
          <FieldGrid>
            <FormField>
              {touched.bundle && errors.bundle && (
                <WarningText>
                  {errors.bundle}
                </WarningText>
              )}
              <Select
                name='bundle'
                placeholder='Pacote...'
                value={values.bundle}
                options={bundles.map (bundle => ({
                  label: bundle.name, value: bundle
                }))}
                onChange={(name, option) => handleBundleSelected (name, option, setFieldValue)}
                onBlur={setFieldTouched}
                error={errors.bundle}
                touched={touched.bundle}
              />     
            </FormField>
            <FormField>
              <Select
                name='professional'
                placeholder='Profissional...'
                value={values.professional}
                options={professionals.map (professional => ({
                  label: professional.nickname || professional.name.split (' ')[0], value: professional.id
                }))}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.professional}
                touched={touched.professional}
              />
            </FormField>
            <FormField>
              <Label htmlFor='bundle_amount'>
                Qtd
              </Label>
              <NumberInput
                id='bundle_amount'
                name='amount'
                value={values.amount}
                minValue={1}
                maxValue={99}
                onChange={(name, value) => {
                  setFieldValue ('custom_price', (values.bundle?.value.price ?? 0) * value)
                  setFieldValue (name, value)
                }}
                style={{width: '100%'}}
              />
            </FormField>
            <FormField>
              <Label htmlFor='bundle_price'>
                Preço
              </Label>
              <MoneyInput
                id='bundle_price'
                name='custom_price'
                placeholder='Valor'
                value={values.custom_price}
                onValueChange={setFieldValue}
                onBlur={handleBlur ('custom_price')}
              />
            </FormField>
            <FormField>
              <Label htmlFor='bundle_comission_percentage'>
                Comissão (%)
              </Label>
              <NumberInput
                id='bundle_comission_percentage'
                name='custom_comission_percentage'
                value={values.custom_comission_percentage}
                minValue={0}
                maxValue={100}
                onChange={setFieldValue}
                style={{width: '100%'}}
              />
            </FormField>
            <Button type='button' onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Adicionar'}
            </Button>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
          </FieldGrid>
          <Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default BillBundleForm
