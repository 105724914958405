import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import EarningsByType from './EarningsByType'
import EarningsByCategory from './EarningsByCategory'
import EarningsByProfessional from './EarningsByProfessional'
import EarningsByPaymentMethod from './EarningsByPaymentMethod'
import EarningsByBillItem from './EarningsByBillItem'
import EarningsAndExpenses from './EarningsAndExpenses'
import BonusesAndDiscounts from './BonusesAndDiscounts'
import CreditsAndDebts from './CreditsAndDebts'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DashboardContainer, LoadingContainer, Title, InfoText } from '../styles'
import { DateTime } from 'luxon'

function Earnings () {
  const now = DateTime.now ().setLocale ('pt-BR')
  const [loading, setLoading] = useState (true)
  const [earningsByType, setEarningsByType] = useState (null)
  const [earningsByProfessional, setEarningsByProfessional] = useState (null)
  const [earningsByCategory, setEarningsByCategory] = useState (null)
  const [earningsByPaymentMethod, setEarningsByPaymentMethod] = useState (null)
  const [earningsByBillItem, setEarningsByBillItem] = useState (null)
  const [earnings, setEarnings] = useState ([])
  const [expenses, setExpenses] = useState ([])
  const [credits, setCredits] = useState ([])
  const [debts, setDebts] = useState ([])
  const [bonuses, setBonuses] = useState ([])
  const [discounts, setDiscounts] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)
      const startDate = now.startOf ('month').toISODate ()
      const endDate = now.toISODate ()
      const filters = `business_id=${businessId}&order_by=created_at&order=desc&cutilei_schedules=true`
                    + '&detailed=true&open=false&status=validated&deleted=false&page=1&per_page=999'
                    + `&start_date=${startDate}&end_date=${endDate}&type=all_earnings`

      const { data: earnings } = await cutileiApi.get (`/earnings?${filters}`, requestConfig)
      const { data: expenses } = await cutileiApi.get (`/expenses?${filters}`, requestConfig)
      const { data: credits } = await cutileiApi.get (
        `/businesses/${businessId}/credits?${filters}&customer_id=all_customers`, requestConfig
      )
      const { data: debts } = await cutileiApi.get (
        `/businesses/${businessId}/debts?${filters}&customer_id=all_customers`, requestConfig
      )
      const { data: bonuses } = await cutileiApi.get (
        `/businesses/${businessId}/credits?${filters}&professional_id=all_professionals`, requestConfig
      )
      const { data: discounts } = await cutileiApi.get (
        `/businesses/${businessId}/debts?${filters}&professional_id=all_professionals`, requestConfig
      )
      const { data: earningsByTypeResponse } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_type?${filters}`, requestConfig
      )
      const { data: earningsByProfessional } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_professional?${filters}`, requestConfig
      )
      const { data: earningsByPaymentMethod } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_payment_method?${filters}`, requestConfig
      )
      const { data: earningsByCategory } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_category?${filters}`, requestConfig
      )

      setEarningsByType (earningsByTypeResponse.earningsByType)
      setEarningsByBillItem (earningsByTypeResponse.earningsByBillItem)
      setEarningsByProfessional (earningsByProfessional)
      setEarningsByPaymentMethod (earningsByPaymentMethod)
      setEarningsByCategory (earningsByCategory)
      setEarnings (earnings)
      setExpenses (expenses)
      setCredits (credits)
      setDebts (debts)
      setBonuses (bonuses)
      setDiscounts (discounts)
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  return loading ? (
    <LoadingContainer>
      <Loading/>
      <InfoText>Gerando gráficos...</InfoText>
    </LoadingContainer>
  ) : (
    <>
      <Title>
        Mês / ano: {now.toFormat ('MMMM / yyyy')}
      </Title>
      <DashboardContainer>
        <EarningsByType data={earningsByType}/>
        <EarningsByProfessional data={earningsByProfessional}/>
        <EarningsByCategory data={earningsByCategory}/>
        <EarningsByPaymentMethod data={earningsByPaymentMethod}/>
        <EarningsByBillItem data={earningsByBillItem}/>
        {(earnings.length > 0 || expenses.length > 0) && (
          <EarningsAndExpenses earnings={earnings} exponses={expenses}/>
        )}
        {(bonuses.length > 0 || discounts.length > 0) && (
          <BonusesAndDiscounts bonuses={bonuses} discounts={discounts}/>
        )}
        {(credits.length > 0 || debts.length > 0) && (
          <CreditsAndDebts credits={credits} debts={debts}/>
        )}
      </DashboardContainer>
    </>
  )
}

export default Earnings
